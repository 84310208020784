import styled from 'styled-components'

export const GifBackground = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url(${p => p.url});
  /* background-position: 27%; */
  box-shadow: inset 0 0 0 50vw rgba(0, 0, 0, 0.7);
`
