import React, { useState } from 'react'
import Ticker from 'react-ticker'
import PageVisibility from 'react-page-visibility'

export const TextTicker = props => {
  const [pageIsVisible, setPageIsVisible] = useState(true)

  const handleVisibilityChange = isVisible => {
    setPageIsVisible(isVisible)
  }

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      {pageIsVisible && (
        <Ticker
        // speed={4}
        // offset="run-in"
        >
          {({ index }) => <>{props.children}</>}
        </Ticker>
      )}
    </PageVisibility>
  )
}
