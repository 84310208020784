import React from 'react'

export const VideoBackground = props => {
  return (
    <div
      className="absolute"
      style={{
        height: '100vh',
        width: '100vw'
      }}
    >
      <video
        height="100%"
        width="100%"
        style={{
          maxWidth: '100%',
          height: '100%'
        }}
        className="object-cover"
        loop
        muted
        autoPlay
        playsInline
      >
        <source src={props.webmSrc} type="video/webm" />
        <source src={props.mp4Src} type="video/mp4" />
      </video>
      <div
        className="absolute w-full h-full top-0 left-0"
        style={{ background: 'rgba(0, 0, 0, 0.7)' }}
      />
    </div>
  )
}
