import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { LogoContainer } from './LogoContainer'
import { GrayImage } from './GrayImage'

export const PartnerLogosSection = () => {
  const { xFit, alexFitness, nrg, havnaGym, armyFit, fizruk } = useStaticQuery(
    graphql`
      query {
        xFit: file(relativePath: { eq: "xfit-new-logo.svg" }) {
          publicURL
        }
        alexFitness: file(relativePath: { eq: "alexfitness-logo.svg" }) {
          publicURL
        }
        nrg: file(relativePath: { eq: "nrgfitness-logo.svg" }) {
          publicURL
        }
        havnaGym: file(relativePath: { eq: "havana-gym-logo.svg" }) {
          publicURL
        }
        armyFit: file(relativePath: { eq: "armyfit-logo.svg" }) {
          publicURL
        }
        fizruk: file(relativePath: { eq: "fizruk-logo.svg" }) {
          publicURL
        }
      }
    `
  )
  return (
    <section className="flex -mx-6 -mt-4 flex-wrap md:flex-no-wrap">
      <LogoContainer>
        <GrayImage src={armyFit.publicURL} alt="" />
      </LogoContainer>
      <LogoContainer>
        <GrayImage src={havnaGym.publicURL} alt="" />
      </LogoContainer>
      <LogoContainer>
        <GrayImage src={xFit.publicURL} alt="" />
      </LogoContainer>
      <LogoContainer>
        <GrayImage src={alexFitness.publicURL} alt="" />
      </LogoContainer>
      <LogoContainer>
        <GrayImage src={nrg.publicURL} alt="" />
      </LogoContainer>
      <LogoContainer>
        <GrayImage src={fizruk.publicURL} alt="" />
      </LogoContainer>
    </section>
  )
}
