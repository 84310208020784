import styled from 'styled-components'

export const GrayImage = styled.img`
  filter: grayscale(1);
  opacity: 0.7;
  transition: ease-out 0.25s;
  cursor: default;
  &:hover {
    filter: none;
    opacity: 1;
  }
  @media (max-width: 768px) {
    min-width: 60px;
    max-width: 120px;
  }
`
