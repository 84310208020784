import React from 'react'

export const useDeviceDetector = () => {
  const [ios, setIos] = React.useState()
  const [android, setAndroid] = React.useState()
  const [isReady, setIsReady] = React.useState()

  React.useEffect(() => {
    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
      setIos(true)
    }
    if (/Android/i.test(navigator.userAgent)) {
      setAndroid(true)
    }
    setIsReady(true)
  }, [])

  return {
    ios,
    android,
    isReady
  }
}
